import Vue from "vue";
import VueRouter from "vue-router";
import VueSessionStorage from 'vue-sessionstorage';
Vue.use(VueSessionStorage);
Vue.use(VueRouter);
const session = Vue.prototype.$session;


const checkAuth = () => (from, to, next) => {
  console.log(from.name);
  // eslint-disable-next-line no-constant-condition
  if (session.get("token") != "" && session.get("token") != null ) {


    return next();
  } else {
    next('/login');
  }
}
const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import('@/views/Login')
  },
  {
    path: "/",
    name: "Main",
    component: () => import('@/views/Main'),
    beforeEnter: checkAuth(),
    children: [
      {
        path: 'applications',
        name: 'Application',
        component: () => import('@/views/Application'),
      },
      {
        path: 'application/:applicationId',
        name: 'ApplicationDetail',
        component: () => import('@/views/ApplicationDetail'),
      },

      {
        path: 'hospital',
        name: 'Hospital',
        component: () => import('@/views/HospitalDetail'),
        props: true
      },
      {
        path: 'hospital/:hospitalId',
        name: 'HospitalDetail',
        component: () => import('@/views/HospitalDetail'),
      },
      {
        path: 'hospital/edit/:hospitalId',
        name: 'HospitalEdit',
        component: () => import('@/views/HospitalEdit'),
      },
      {
        path: 'qna',
        name: 'Qna',
        component: () => import('@/views/Qna'),
      },
      {
        path: 'calculate',
        name: 'Calculate',
        component: () => import('@/views/Calculate'),
      },
      {
        path: 'calculate/:applicationId',
        name: 'CalculateDetail',
        component: () => import('@/views/CalculateDetail'),
      },
      {
        path: 'doctor',
        name: 'HospitalDoctor',
        component: () => import('@/views/HospitalDoctor'),
      },
      {
        path: 'notice/new',
        name: 'NoticeNew',
        component: () => import('@/views/ContentsEdit'),
      },
      {
        path: 'notice/edit/:id',
        name: 'NoticeEdit',
        component: () => import('@/views/ContentsEdit'),
      },
      {
        path: 'notice/new',
        name: 'NoticeNew',
        component: () => import('@/views/ContentsEdit'),
      },
      {
        path: 'notice/:id',
        name: 'NoticeDetail',
        component: () => import('@/views/ContentsDetail'),
      },
      {
        path: 'banner',
        name: 'Banner',
        component: () => import('@/views/Banner'),
      },
      {
        path: 'banner/edit/:id',
        name: 'BannerEdit',
        component: () => import('@/views/BannerEdit'),
      },
      {
        path: 'banner/new',
        name: 'BannerEdit',
        component: () => import('@/views/BannerEdit'),
      },
      {
        path: 'fund',
        name: 'FundSeat',
        component: () => import('@/views/FundSeat'),
      },
      {
        path: 'fund/new',
        name: 'FundSeatNew',
        component: () => import('@/views/FundSeatEdit'),
      },
      {
        path: 'fund/edit/:id',
        name: 'FundSeatEdit',
        component: () => import('@/views/FundSeatEdit'),
      },
      {
        path: 'health-column',
        name: 'HealthColumn',
        component: () => import('@/views/HospitalColumn'),
      },
      {
        path: 'health-column/new',
        name: 'HealthColumnNew',
        component: () => import('@/views/ContentsEdit'),
      },
      {
        path: 'health-column/edit/:id',
        name: 'HealthColumnEdit',
        component: () => import('@/views/ContentsEdit'),
      },
      {
        path: 'health-column/:id',
        name: 'HealthColumnDetail',
        component: () => import('@/views/ContentsDetail'),
      },
      {
        path: 'faq',
        name: 'Faq',
        component: () => import('@/views/Contents'),
      },
      {
        path: 'faq/new',
        name: 'FaqNew',
        component: () => import('@/views/ContentsEdit'),
      },
      {
        path: 'faq/edit/:id',
        name: 'FaqEdit',
        component: () => import('@/views/ContentsEdit'),
      },
      {
        path: 'faq/:id',
        name: 'FaqDetail',
        component: () => import('@/views/ContentsDetail'),
      },
      {
        path: 'option',
        name: 'Option',
        component: () => import('@/views/Option'),
      },
      {
        path: 'option/edit/:id',
        name: 'OptionEdit',
        component: () => import('@/views/OptionEdit'),
      },
      {
        path: 'option/new',
        name: 'optionNew',
        component: () => import('@/views/OptionEdit'),
      },
      {
        path: 'member',
        name: 'Member',
        component: () => import('@/views/Member'),
      },
      {
        path: 'setting',
        name: 'Setting',
        component: () => import('@/views/Setting'),
      }

    ]
  }
];

const router = new VueRouter({
  mode: "hash",
  linkActiveClass: 'open active',
  base: process.env.BASE_URL,
  routes
});

export default router;
